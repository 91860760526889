<template>
  <div style="max-width: 1200px">
    <h2>Antragsfragen Admin {{ tarif.tier_title }}</h2>
    <a class="my-2" @click="addNeuItem()">neue Frage hinzufügen</a>

    <v-row class="my-4" v-for="item in fragen" :key="item.antrag_id">
      <v-col cols="12" md="1">
        <a @click="item.show_edit = !item.show_edit">ID: {{ item.antrag_id }}</a
        ><br />
        Nr. {{ item.antrag_old }}<br />

        Ober: {{ item.antrag_oberfrage }}</v-col
      >

      <v-col cols="12" md="11"
        ><b
          ><a @click="item.show_edit = !item.show_edit">{{
            item.antrag_text
          }}</a></b
        >
        <div
          v-if="item.antrag_desc"
          class="pa-2"
          style="background-color: #f2f2f2"
        >
          <br />
          {{ item.antrag_desc }}
        </div>
        <v-dialog v-model="item.show_edit" max-width="1000">
          <v-card class="pa-4">
            <v-card-text
              ><h2>{{ item.antrag_text }}</h2></v-card-text
            >

            <v-row>
              <v-col cols="12" md="2">Nummer der Frage</v-col>
              <v-col cols="12" md="10">
                <v-text-field v-model="item.antrag_old" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2">Art der Frage</v-col>
              <v-col cols="12" md="10">
                <v-radio-group v-model="item.antrag_art">
                  <v-radio label="Ja/Nein" value="1" />
                  <v-radio label="Zahlen" value="2" />
                  <v-radio label="Textfeld" value="3" /> </v-radio-group
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2">Text</v-col>
              <v-col cols="12" md="10"
                ><v-textarea v-model="item.antrag_text"
              /></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2">Default Wert</v-col>
              <v-col cols="12" md="10"
                ><v-textarea v-model="item.antrag_input_default"
              /></v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2">Hinweis zeigen</v-col>
              <v-col cols="12" md="10">
                <v-radio-group v-model="item.antrag_desc_show">
                  <v-radio label="Nein" value="0" />
                  <v-radio label="Ja" value="1" /> </v-radio-group
              ></v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2">Hinweis</v-col>
              <v-col cols="12" md="10"
                ><v-textarea v-model="item.antrag_desc"
              /></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2">Daten aus Step1</v-col>
              <v-col cols="12" md="10">
                <v-radio-group v-model="item.antrag_ber_field">
                  <v-radio label="Lücke ja/nein" value="ber_luecke_ja" />
                  <v-radio
                    label="Anzahl fehlende Zähne"
                    value="ber_luecke_count"
                  /> </v-radio-group
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2">Antwortpflicht</v-col>
              <v-col cols="12" md="10">
                <v-radio-group v-model="item.antrag_input_ja">
                  <v-radio label="egal" value="" />
                  <v-radio
                    label="Frage muss mit JA beantwortet sein"
                    value="ja"
                  />
                  <v-radio
                    label="Frage muss mit Nein beantwortet sein"
                    value="nein"
                  /> </v-radio-group
              ></v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2">Oberfrage</v-col>
              <v-col cols="12" md="10">
                <v-radio-group v-model="item.antrag_oberfrage">
                  <v-radio
                    v-for="frage in fragen"
                    :key="frage.antrag_id"
                    :label="frage.antrag_text"
                    :value="frage.antrag_id"
                  /> </v-radio-group
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2"></v-col>
              <v-col cols="12" md="2"
                ><v-btn @click="updateFrage(item)" color="blue" outlined
                  >speichern</v-btn
                ></v-col
              >
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "index-antrag-admin",
  components: {},

  data: function () {
    return {
      tarif: {
        tier_title: "",
      },
      select: {
        type: "einzel",
        id: this.$route.params.id,
        number: "one",
      },
      insert: {
        type: "frage",
        tarif_id: this.$route.params.id,
      },
      update: {
        antrag_id: 0,
        antrag_wtb_id: this.$route.params.id,
      },
      fragen: false,
      showInsertSuccess: false,
    };
  },
  created() {
    this.getTarife();
  },

  methods: {
    getTarife() {
      this.$store
        .dispatch("getTarife", this.select)
        .then((resp) => {
          if (resp.data.tarife) {
            this.tarif = resp.data.tarife;
            this.getTarifAntragFragen();
          }
        })
        .catch((err) => {
          this.$log.error(err);
        });
    },
    getTarifAntragFragen() {
      this.$store
        .dispatch("getTarifAntragFragen", this.select)
        .then((resp) => {
          if (resp.data.fragen) {
            this.fragen = resp.data.fragen;
          }
        })
        .catch((err) => {
          this.$log.error(err);
        });
    },
    addNeuItem() {
      this.$store.commit("start_loading_full");
      this.$store.dispatch("insertNeueFrage", this.insert).then((resp) => {
        this.$store.commit("stop_loading_full");
        this.getTarifAntragFragen(this.tarif.tarif_id);
      });
    },
    getFragen(tarif_id) {
      this.$store.dispatch("getTarif", tarif_id).then((resp) => {
        this.fragen = resp.data.fragen;
      });
    },

    updateFrage(item) {
      this.$store.commit("start_loading_full");
      this.update = item;
      this.$store.dispatch("updateAntragFrage", this.update).then((resp) => {
        this.$store.commit("stop_loading_full");
        this.getTarifAntragFragen(this.tarif.tier_id);
        this.update.antrag_id = 0;
      });
    },
  },
};
</script>
